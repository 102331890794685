import React from "react";

const NotFoundPage = () => (
  <div>
    <h1>404</h1>
    <p>Page not found.</p>
  </div>
);

export default NotFoundPage;
